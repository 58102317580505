import React from "react";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import { TFunction } from "i18next";
import MainLayout from "./layouts/Main";
import MessagesPage from "./pages/Messages";
import ApplicationPage from "./pages/Application";
import JobListPage from "./pages/JobList";
import ApplicationsPage from "./pages/Applications";
import CompanySettingsPage from "./pages/CompanySettings";
import SingleJobPage from "./pages/SingleJob";
import TeamSettingsPage from "./pages/TeamSettings";
import MagicLinkConfirmationPage from "./pages/MagicLinkConfirmation";
import LoginPage from "./pages/Login";
import ResetPasswordPage from "./pages/Password/Reset";
import NewPasswordPage from "./pages/Password/NewPassword";
import ResendPasswordPage from "./pages/Password/Resend";
import ConfirmationPasswordPage from "./pages/Password/Confirmation";
import CompanyRegisterPage from "./pages/Registration/Company";
import RegisterCompanyPage from "./pages/Registration/Company";
import RecruiterRegisterPage from "./pages/Registration/Recruiter";
import ResendEmailRegisterPage from "./pages/Registration/Resend";
import JobPreviewPage from "./pages/JobPreview";
import AdditionalQuestionsPage from "./pages/AdditionalQuestions";
import CandidateDashboardPage from "./pages/CandidateDashboard";
import ConfirmationRegisterPage from "./pages/Registration/Confirmation";
import TemplatePreviewPage from "./pages/TemplatePreview";
import CompanyInfoPage from "./pages/CompanyInfo";
import AppliedResendEmailPage from "./pages/AppliedResendEmail";
import JobWidgetPage from "./pages/JobWidgetPage";
import CustomerPage from "./pages/Customers";
import CustomerDetailsPage from "./pages/CustomerDetails";
import NewMessageRedirectionPage from "./pages/NewMessageRedirection";
import AgencyExistingCompanyConfirmationPage from "./pages/Registration/AgencyExistingCompanyConfirmation";
import DashboardPage from "./pages/Dashboard";
import TalentPoolsPage from "./pages/TalentPools";
import TalentPoolPage from "./pages/TalentPool";
import UserSettingsPage from "./pages/UserSettings";
import DiscoverPage from "./pages/Discover";
import InventoryPage from "./pages/Inventory";
import ProductPage from "./pages/Product";
import PackagePage from "./pages/Package";
import DetailedApplicationPage from "./pages/TalentPool/DetailedApplication";
import WorkflowPage from "./pages/Workflow";
import AnalyticsPage from "./pages/Analytics";
import MarketingPage from "./pages/MarketingPage";
import AutomationsPage from "./pages/Automations";
import AutomationPage from "./pages/Automation";
import CareerPage from "./pages/Career";
import { PAGE_TYPES } from "./models/TopHeader";
import { SIDEBAR_MENU_ITEM_IDS } from "./models/Menu";

const getRouter = ({
  desktop,
  t,
  token,
  isCandidate,
}: {
  desktop: boolean;
  t: TFunction;
  token: string | null;
  isCandidate: boolean;
}) => {
  const PrivateRoute = () => {
    const path = window.location.pathname;
    if (!token) {
      if (path.includes("/applications/candidate/")) {
        sessionStorage.setItem("redirection-url", window.location.pathname);
      }
      return <Navigate to={"/login"} replace />;
    }
    return <MainLayout />;
  };

  const UnauthorizedRoute = () => {
    if (token)
      return (
        <Navigate
          to={
            isCandidate !== undefined && isCandidate === false
              ? `/dashboard`
              : `/`
          }
          replace
        />
      );

    return <Outlet />;
  };

  return createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "/applications",
          element: <ApplicationsPage />,
          handle: {
            title: t("navigation.applications"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.APPLICATIONS,
          },
        },
        {
          path: "/customers",
          element: <CustomerPage />,
          handle: {
            title: t("navigation.customers"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.CUSTOMERS,
          },
        },
        {
          path: "/customer/details/:id",
          element: <CustomerDetailsPage />,
          handle: {
            title: "",
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.CUSTOMERS,
            pageType: PAGE_TYPES.CUSTOMER_DETAILS,
          },
        },
        {
          path: "/applications/candidate/:key/:job_id",
          element: <ApplicationPage desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.APPLICATIONS,
          },
        },
        {
          path: "/jobs/applications/candidate/:key/:job_id",
          element: <ApplicationPage desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOBS,
          },
        },
        {
          path: "/talentpool/:talentpoolId/applications/candidate/:key/:job_id",
          element: <ApplicationPage desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/talentpool/:talentpoolId/candidate/",
          element: <DetailedApplicationPage desktop={desktop} />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_APPLICATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/candidate-dashboard/job/:key",
          element: <ApplicationPage desktop={desktop} />,
          handle: { pageType: PAGE_TYPES.SINGLE_APPLICATION_CANDIDATE },
        },
        {
          path: "/",
          element: <CandidateDashboardPage desktop={desktop} />,
          handle: {
            title: "Dashboard",
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
          },
        },
        {
          path: "/dashboard",
          element: <DashboardPage />,
          handle: {
            title: "Dashboard",
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DASHBOARD,
          },
        },
        {
          path: "/talentpools",
          element: <TalentPoolsPage />,
          handle: {
            title: t("talentPools.talentPools"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/talentpool/:id",
          element: <TalentPoolPage />,
          handle: {
            pageType: PAGE_TYPES.TALENT_POOL,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TALENTPOOLS,
          },
        },
        {
          path: "/messages",
          element: <MessagesPage desktop={desktop} />,
          handle: {
            title: t("navigation.messages"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.MESSAGES,
            pageType: PAGE_TYPES.MESSAGES_MOBILE,
          },
        },
        {
          path: "/analytics",
          element: <AnalyticsPage />,
          handle: {
            title: t("navigation.analytics"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.ANALYTICS,
          },
        },
        {
          path: "/jobs/:tab",
          element: <JobListPage />,
          handle: { title: "Jobs", sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOBS },
        },
        {
          path: "/jobs/:key/:tab",
          element: <SingleJobPage />,
          handle: {
            pageType: PAGE_TYPES.SINGLE_JOB,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOBS,
          },
        },
        {
          path: "/user-settings",
          element: <UserSettingsPage desktop={desktop} />,
          handle: {
            title: t("userSettings.user_settings"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.USER_SETTINGS,
          },
        },
        {
          path: "/company-settings",
          element: <CompanySettingsPage />,
          handle: {
            title: t("navigation.company_profiles"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.COMPANY_SETTINGS,
          },
        },
        {
          path: "/career",
          element: <CareerPage />,
          handle: {
            title: t("navigation.career_page"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.CAREER_PAGE,
          },
        },
        {
          path: "/team/settings/:tab",
          element: <TeamSettingsPage />,
          handle: { title: "Team", sidebarMenu: SIDEBAR_MENU_ITEM_IDS.TEAM },
        },
        {
          path: "/workflow",
          element: <WorkflowPage />,
          handle: {
            title: t("navigation.workflow"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.WORKFLOW,
          },
        },
        {
          path: "/automations",
          element: <AutomationsPage />,
          handle: {
            title: t("navigation.automation"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.AUTOMATION,
          },
        },
        {
          path: "/automation",
          element: <AutomationPage />,
          handle: {
            pageType: PAGE_TYPES.AUTOMATION,
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.AUTOMATION,
          },
        },
        {
          path: "/job-widget",
          element: <JobWidgetPage desktop={desktop} />,
          handle: {
            title: t("job_widget.title"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.JOB_WIDGET,
          },
        },
        {
          path: "/discover",
          element: <DiscoverPage />,
          handle: {
            title: t("navigation.discover_all_products"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
          },
        },
        {
          path: "/product",
          element: <ProductPage />,
          handle: {
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
            pageType: PAGE_TYPES.PRODUCT,
          },
        },
        {
          path: "/package",
          element: <PackagePage />,
          handle: {
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.DISCOVER,
            pageType: PAGE_TYPES.PACKAGE,
          },
        },
        {
          path: "/inventory",
          element: <InventoryPage />,
          handle: {
            title: t("navigation.inventory"),
            sidebarMenu: SIDEBAR_MENU_ITEM_IDS.INVENTORY,
          },
        },
      ],
    },
    {
      path: "/",
      element: <UnauthorizedRoute />,
      children: [
        { path: "/login", element: <LoginPage desktop={desktop} /> },
        {
          path: "/forgot-password",
          element: <ResetPasswordPage desktop={desktop} />,
        },
        {
          path: "/forgot-password/resend",
          element: <ResendPasswordPage desktop={desktop} />,
        },
        {
          path: "/password/confirmation",
          element: <ConfirmationPasswordPage desktop={desktop} />,
        },
        {
          path: "/password/recovery/:code",
          element: <NewPasswordPage desktop={desktop} />,
        },
        {
          path: "/register/company",
          element: <CompanyRegisterPage desktop={desktop} />,
        },
        {
          path: "/register/recruiter",
          element: <RecruiterRegisterPage desktop={desktop} />,
        },
        {
          path: "/registration/invitation-confirm/:urlKey",
          element: <RecruiterRegisterPage desktop={desktop} />,
        },
        {
          path: "/registration/agency/invitation-accept/:urlKey",
          element: <AgencyExistingCompanyConfirmationPage />,
        },
        {
          path: "/registration/agency/invitation-confirm/:urlKey",
          element: <RegisterCompanyPage desktop={desktop} />,
        },
        {
          path: "/register/resend-email",
          element: <ResendEmailRegisterPage desktop={desktop} />,
        },
      ],
    },
    {
      path: "/magic-link-confirmation",
      element: <MagicLinkConfirmationPage desktop={desktop} />,
    },
    {
      path: "/template/preview/:id",
      element: <TemplatePreviewPage desktop={desktop} />,
    },
    {
      path: "/companies/:companyname",
      element: <CompanyInfoPage desktop={desktop} />,
    },
    { path: "/job/:key", element: <JobPreviewPage desktop={desktop} /> },
    { path: "/marketing", element: <MarketingPage desktop={desktop} /> },
    {
      path: "/candidate/login/:code", // TODO check this route
      element: <AdditionalQuestionsPage desktop={desktop} />,
    },
    {
      path: "/candidate/login/additional-questions/:code",
      element: <AdditionalQuestionsPage desktop={desktop} />,
    },
    {
      path: "/messages/:code/:jobId/:applicationId/:recruiterId",
      element: <NewMessageRedirectionPage />,
    },
    {
      path: "/registration/confirm/:code",
      element: <ConfirmationRegisterPage />,
    },
    {
      path: "/successful-application",
      element: <AppliedResendEmailPage desktop={desktop} />,
    },
  ]);
};

export default getRouter;
