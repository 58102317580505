import React from "react";
import { Button, Stack } from "@mui/material";
import GeneralInfo from "./components/GeneralInfo";
import TechnicalInformation from "./components/TechnicalInformation";
import Loading from "../../components/Loading";
import { ICompanySettingsComponent } from "../../models/CompanySettings";
import { StyledCompanySettingsContainer } from "./styles";

const CompanySettingsComponent = ({
  t,
  errors,
  control,
  countries,
  company,
  isFormChanged,
  isFormDataLoading,
  register,
  onCopyToken,
  onSubmit,
  onOpenDisconnectDialog,
}: ICompanySettingsComponent) =>
  isFormDataLoading ? (
    <Loading />
  ) : (
    <form data-testid={"company-settings-form-wrapper"}>
      <StyledCompanySettingsContainer spacing={2}>
        <GeneralInfo
          t={t}
          register={register}
          errors={errors}
          control={control}
          countries={countries}
          company={company}
          onOpenDisconnectDialog={onOpenDisconnectDialog}
        />
        <TechnicalInformation
          t={t}
          register={register}
          onCopyToken={onCopyToken}
        />
        <Stack px={2.15} alignItems="flex-end">
          <Button
            className={"submit-btn"}
            size="small"
            disableElevation
            variant="contained"
            onClick={onSubmit}
            disabled={!isFormChanged || Boolean(Object.keys(errors)?.length)}
          >
            {t("button.save")}
          </Button>
        </Stack>
      </StyledCompanySettingsContainer>
    </form>
  );

export default CompanySettingsComponent;
