import React, { useState, useEffect, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  BanknotesIcon,
  BriefcaseIcon,
  FolderOpenIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CompanyHeader from "./CompanyHeader";
import Video from "./Video";
import JobTemplateSection from "./JobTemplateSection";
import JobLocations from "../../../components/JobLocations";
import { getJobPreviewState } from "../../../store/selectors/JobPreview";
import {
  StyledJobPreviewJobDescriptionHeader,
  StyledJobPreviewTemplateSectionContent,
} from "../styles";
import { convertSalaryString, getSalarySymbol } from "../../../utils/common";
import { getCurrentUserData } from "../../../store/selectors/CurrentUser";
import { getCompanySettingsCompanyState } from "../../../store/selectors/Company";
import { formatLogoUrl } from "../../../utils";
import { TCompany } from "../../../models/CompanyInfo";

const JobDescription = ({
  desktop,
  companyColor,
}: {
  readonly desktop: boolean;
  readonly companyColor: string;
}) => {
  const { t } = useTranslation();

  const [isShowMore, setIsShowMore] = useState<boolean>(true);
  const [isShowMoreExist, setIsShowMoreExist] = useState<boolean>(false);
  const [shortCompanyDescription, setShortCompanyDescripiton] =
    useState<string>("");

  const { jobTemplate, jobLocations, jobData } =
    useSelector(getJobPreviewState);
  const { company } = useSelector(getCompanySettingsCompanyState);
  const { isAgency } = useSelector(getCurrentUserData);

  const noLocations = `${t("update_company_information.city")}, ${t(
    "update_company_information.country"
  )}`;
  const mainLogo = jobTemplate?.logo;
  const agencyCustomerName = localStorage.getItem("company-name");
  const customerName = isAgency
    ? agencyCustomerName || ""
    : jobTemplate?.company;

  const formattedLogo = isAgency
    ? null
    : mainLogo
      ? formatLogoUrl(mainLogo)
      : null;

  const salarySymbol = getSalarySymbol(jobTemplate?.salary_currency || "");
  const { minSalary, maxSalary } = useMemo(
    () => ({
      minSalary: convertSalaryString(jobTemplate?.min_salary),
      maxSalary: convertSalaryString(jobTemplate?.max_salary),
    }),
    [jobTemplate?.max_salary, jobTemplate?.min_salary]
  );

  const formattedCompany = {
    title: customerName,
    about_us: jobData?.company_description || company?.about_us,
    industry: jobData?.company_industry || company?.industry,
    color: companyColor,
    website: jobTemplate?.company_website,
    logo: formattedLogo as string,
    linkedin_url: jobData?.company_linkedin || company?.linkedin,
    xing_url: jobData?.company_xing || company?.xing,
    employees: jobData?.company_employees || company?.employees,
  };

  const handleOnShowMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  useEffect(() => {
    if (company?.about_us && isShowMore) {
      const companyAboutUsElement: any =
        document.querySelector(".company-about-us");
      const isShowMoreCheck =
        companyAboutUsElement?.offsetHeight <
        companyAboutUsElement?.scrollHeight
          ? true
          : false;
      setIsShowMoreExist(isShowMoreCheck);
      const computedStyle = getComputedStyle(companyAboutUsElement);

      const width = computedStyle
        ? parseInt(computedStyle.getPropertyValue("width"))
        : 0;

      const fullText = companyAboutUsElement.textContent;
      const chartWidth = 6.3;
      const maximumTextRows = 5;
      const maxCharacters = Math.floor((width / chartWidth) * maximumTextRows);
      const shortText = fullText.slice(0, maxCharacters);
      const spacingForShowMoreText = desktop ? -10 : -20;
      const formattedShortText = shortText.slice(0, spacingForShowMoreText);
      const elipsisShortText = formattedShortText + "...";
      setShortCompanyDescripiton(elipsisShortText);
    }
  }, [company?.about_us]);

  return (
    <Stack mt={desktop ? "-29px !important" : "20px !important"} spacing={3}>
      <StyledJobPreviewJobDescriptionHeader>
        <Typography fontSize={20} fontWeight={500} pb={1} color={companyColor}>
          {jobTemplate?.title ||
            t("create_job_first_step.job_title_placeholder")}
        </Typography>
        <StyledJobPreviewTemplateSectionContent
          direction={desktop ? "row" : "column"}
          sx={{ flexWrap: "wrap", gap: "16px" }}
          companyColor={companyColor}
        >
          {!jobLocations ? (
            <Box>{noLocations}</Box>
          ) : (
            <JobLocations
              desktop={desktop}
              spacing={1}
              jobLocations={jobLocations}
              companyColor={companyColor}
              containerMaxWidth={360}
              itemMaxWidth={110}
            />
          )}
          <Stack direction={"row"} spacing={1}>
            <BriefcaseIcon />
            <Box>
              {jobTemplate?.position_type ||
                t("create_job_second_step.job_type")}
            </Box>
          </Stack>
          <Stack direction={"row"} spacing={1}>
            <FolderOpenIcon />
            <Box>
              {jobTemplate?.work_type ||
                t("create_job_second_step.field_of_work")}
            </Box>
          </Stack>
          {minSalary && maxSalary && (
            <Stack direction={"row"} spacing={1}>
              <BanknotesIcon />
              <Box>
                {t("jobPreview.salary", {
                  currency: salarySymbol,
                  min: minSalary,
                  max: maxSalary,
                })}
                {jobTemplate?.salary_type && (
                  <>
                    <span> / </span>
                    {t(
                      `singleJob.jobDetails.salary.type.${jobTemplate?.salary_type}`
                    )}
                  </>
                )}
              </Box>
            </Stack>
          )}
        </StyledJobPreviewTemplateSectionContent>
      </StyledJobPreviewJobDescriptionHeader>

      <JobTemplateSection
        title={jobTemplate?.template_introduction_title || ""}
        content={jobTemplate?.template_introduction || ""}
        companyColor={companyColor}
        desktop={desktop}
        isFirstElement
      />
      <JobTemplateSection
        title={jobTemplate?.template_tasks_title || ""}
        content={jobTemplate?.template_tasks || ""}
        companyColor={companyColor}
        desktop={desktop}
      />
      <JobTemplateSection
        title={jobTemplate?.template_profile_title || ""}
        content={jobTemplate?.template_profile || ""}
        companyColor={companyColor}
        desktop={desktop}
      />
      <JobTemplateSection
        title={jobTemplate?.template_offer_title || ""}
        content={jobTemplate?.template_offer || ""}
        companyColor={companyColor}
        desktop={desktop}
      />
      {jobTemplate?.template_contact_title && (
        <JobTemplateSection
          title={jobTemplate?.template_contact_title || ""}
          content={jobTemplate?.template_contact || ""}
          companyColor={companyColor}
          desktop={desktop}
        />
      )}
      {jobTemplate?.template_video_url && (
        <Stack pt={1}>
          <Video url={jobTemplate?.template_video_url} />
        </Stack>
      )}
      <Stack>
        <CompanyHeader
          t={t}
          desktop={desktop}
          company={formattedCompany as unknown as TCompany}
          isShowMore={isShowMore}
          industry={formattedCompany.industry}
          isShowMoreExist={isShowMoreExist}
          shortCompanyDescription={shortCompanyDescription}
          onShowMoreLess={handleOnShowMoreLess}
        />
      </Stack>
    </Stack>
  );
};

export default JobDescription;
