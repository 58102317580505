import React from "react";
import { Chip, InputLabel, Stack, Typography } from "@mui/material";
import Media from "./Media";
import { TCompanyInfoMedia } from "../../models/CompanyInfo";
import { StyledCompanyDescription } from "./style";
import { StyledChipsContainer } from "../../components/ChipsManager/styles";

const About = ({
  t,
  desktop,
  isShowMore,
  isShowMoreExist,
  shortCompanyDescription,
  company,
  onShowMoreLess,
}: TCompanyInfoMedia) => (
  <Stack
    direction={desktop ? "row" : "column"}
    pt={3}
    width={desktop ? "100%" : "90%"}
    margin="auto"
    maxWidth={desktop ? 1128 : "auto"}
    spacing={3}
  >
    {!company.about_us?.length && !company.benefits.length ? null : (
      <Stack flex={1} spacing={1.25} width="100%">
        {company?.about_us && (
          <Stack spacing={0.5} width="100%">
            <InputLabel sx={{ right: 4 }}>
              {t("company_settings.about_us")}
            </InputLabel>
            {!isShowMoreExist && (
              <StyledCompanyDescription
                className="company-about-us"
                isShowMore={isShowMore}
                variant="subtitle2"
                color="textSecondary"
              >
                {company.about_us}
              </StyledCompanyDescription>
            )}
            <>
              {isShowMore ? (
                <Typography
                  className="company-about-us"
                  display="inline"
                  variant="subtitle2"
                  color="textSecondary"
                  textAlign="justify"
                  sx={{ wordBreak: "break-word", textJustify: "inter-word" }}
                >
                  {isShowMoreExist ? shortCompanyDescription : null}
                  {isShowMoreExist && (
                    <Typography
                      display="inline"
                      variant="subtitle2"
                      color="secondary"
                      whiteSpace="nowrap"
                      onClick={onShowMoreLess}
                      sx={{ cursor: "pointer" }}
                    >
                      {t("company_page.show_more")}
                    </Typography>
                  )}
                </Typography>
              ) : (
                <Typography
                  display="inline"
                  variant="subtitle2"
                  color="textSecondary"
                  textAlign="justify"
                  sx={{ wordBreak: "break-word", textJustify: "inter-word" }}
                >
                  {company.about_us}
                  <Typography
                    display="inline"
                    variant="subtitle2"
                    color="secondary"
                    whiteSpace="nowrap"
                    onClick={onShowMoreLess}
                    sx={{ cursor: "pointer" }}
                  >
                    {t("company_page.show_less")}
                  </Typography>
                </Typography>
              )}
            </>
          </Stack>
        )}
        {!!company.benefits.length && (
          <Stack width="100%">
            <InputLabel sx={{ right: 4 }}>Benefits</InputLabel>
            <StyledChipsContainer sx={{ border: "none" }}>
              {company.benefits.map((chip: string, index: number) => (
                <Chip
                  className="chips-manager"
                  size="medium"
                  key={chip + index}
                  label={chip}
                />
              ))}
            </StyledChipsContainer>
          </Stack>
        )}
      </Stack>
    )}
    {desktop &&
      (company.video_link ||
      company.picture_one ||
      company.picture_two ||
      company.picture_three ? (
        <Stack flex={1}>
          <Media company={company} />
        </Stack>
      ) : null)}
  </Stack>
);

export default About;
