import React from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import IconWithTooltip from "./IconWithTooltip";
import RadialBarChart from "../../components/Charts/RadialBarChart";
import {
  getAnalyticsNOAIsLoadingState,
  getAnalyticsSOHTargetSources,
  getAnalyticsSOHTargetValues,
} from "../../store/selectors/Analytics";
import {
  StyledChartContainer,
  StyledChartContainerTitle,
  StyledNoDataContainer,
} from "./styles";
import { getPerformedName } from "./utils";

const SourceOfHire = () => {
  const { t } = useTranslation();
  const isLoading = useSelector(getAnalyticsNOAIsLoadingState);
  const values = useSelector(getAnalyticsSOHTargetValues);
  const sources = useSelector(getAnalyticsSOHTargetSources);

  return (
    <StyledChartContainer>
      <StyledChartContainerTitle>
        <h4>{t("analytics.sourceOfHire")}</h4>
        <IconWithTooltip title={t("analytics.infoText.sourceOfHire")} />
      </StyledChartContainerTitle>
      {isLoading ? (
        <Stack maxHeight={350}>
          <Loading />
        </Stack>
      ) : values?.length ? (
        <RadialBarChart
          isMultiple={values.length > 1}
          series={values}
          labels={
            sources.map((source) =>
              source
                ? t(
                    getPerformedName({
                      name: source,
                      path: "source",
                    })
                  )
                : t("analytics.unknown")
            ) || []
          }
        />
      ) : (
        <StyledNoDataContainer>
          <h5>{t("analytics.noDataText")}</h5>
          <h6>{t("analytics.changeDateFilter")}</h6>
        </StyledNoDataContainer>
      )}
    </StyledChartContainer>
  );
};

export default SourceOfHire;
