import React, { useEffect, useState } from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { IconButton, Stack } from "@mui/material";
import Video from "../JobPreview/JobTemplate/Video";
import FullScreenImageDialog from "../../components/dialogs/FullScreenImageDialog";
import { StyledCompanyMedia, StyledMediaImage } from "./style";
import { TCompany } from "../../models/CompanyInfo";

const fullScreenIconStyle = {
  cursor: "pointer",
};

const Media = ({ company }: { readonly company: TCompany }) => {
  const [selectedMedia, setSelectedMedia] = useState<string | undefined>(
    undefined
  );
  const [isFullScreenDialogOpen, setIsFullScreenDialogOpen] = useState(false);

  const getYouTubeThumbnail = (url: string) => {
    const regex = /(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      return `https://img.youtube.com/vi/${match[1]}/maxresdefault.jpg`;
    }
    return null;
  };

  const thumbnailUrl = company.video_link
    ? getYouTubeThumbnail(company.video_link)
    : undefined;

  const handleCloseFullScreenDialog = () => {
    setIsFullScreenDialogOpen(false);
  };

  useEffect(() => {
    if (!selectedMedia && company) {
      if (company.video_link) {
        setSelectedMedia(company.video_link);
      } else if (company.picture_one) {
        setSelectedMedia(company.picture_one);
      } else if (company.picture_two) {
        setSelectedMedia(company.picture_two);
      } else if (company.picture_three) {
        setSelectedMedia(company.picture_three);
      }
    }
  }, [company]);

  return (
    <StyledCompanyMedia>
      <FullScreenImageDialog
        isOpen={isFullScreenDialogOpen}
        selectedImage={selectedMedia as string}
        onClose={handleCloseFullScreenDialog}
      />
      <Stack height="100%" spacing={1.5}>
        {company.video_link && (
          <StyledMediaImage
            isSelected={selectedMedia === company.video_link}
            onClick={() => setSelectedMedia(company.video_link as string)}
            src={thumbnailUrl as string}
            alt="company-media-video-image"
          />
        )}
        {company.picture_one && (
          <StyledMediaImage
            isSelected={selectedMedia === company.picture_one}
            onClick={() => setSelectedMedia(company.picture_one as string)}
            src={company.picture_one as string}
            alt="company-media-video-image"
            className="company-media-image selected"
          />
        )}
        {company.picture_two && (
          <StyledMediaImage
            isSelected={selectedMedia === company.picture_two}
            onClick={() => setSelectedMedia(company.picture_two as string)}
            src={company.picture_two as string}
            alt="company-media-video-image"
          />
        )}
        {company.picture_three && (
          <StyledMediaImage
            isSelected={selectedMedia === company.picture_three}
            onClick={() => setSelectedMedia(company.picture_three as string)}
            src={company.picture_three as string}
            alt="company-media-video-image"
          />
        )}
      </Stack>
      <Stack direction="row" width="100%" height="100%" spacing={1.5}>
        {selectedMedia?.includes("karriera") ? (
          <Stack position="relative" width="100%" height="100%">
            <img src={selectedMedia} width="100%" height="100%" />
            <IconButton
              onClick={() => setIsFullScreenDialogOpen(true)}
              sx={{
                position: "absolute",
                width: 24,
                height: 24,
                padding: 0,
                right: 8,
                bottom: 8,
                backgroundColor: "grey",
                "&:hover": {
                  backgroundColor: "lightgrey",
                },
              }}
            >
              <FullscreenIcon
                color="info"
                sx={{
                  fontSize: 16,
                }}
                style={fullScreenIconStyle}
              />
            </IconButton>
          </Stack>
        ) : (
          <Stack width="100%">
            <Video url={company.video_link as string} />
          </Stack>
        )}
      </Stack>
    </StyledCompanyMedia>
  );
};

export default Media;
