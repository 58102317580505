import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CompanyInfoComponent from "./component";
import {
  fetchCompanyInfo,
  setCompanyFilter,
  setPageNum,
} from "../../store/reducers/CompanyInfo";
import {
  getCompanyInfoPagination,
  getCompanyInfoState,
  getCompanySettingsCompanyState,
} from "../../store/selectors/Company";

const CompanyInfo = ({ desktop }: { readonly desktop: boolean }) => {
  const { t } = useTranslation();
  const { companyname } = useParams();
  const dispatch = useDispatch();

  const { isLoading, company, filterOptions, filterValue, error } =
    useSelector(getCompanyInfoState);

  const { company: companySettings } = useSelector(
    getCompanySettingsCompanyState
  );
  const industry = useMemo(
    () => companySettings?.industry || "",
    [companySettings]
  );
  const { pageNum, pageSize } = useSelector(getCompanyInfoPagination);

  const [isShowMore, setIsShowMore] = useState<boolean>(true);
  const [isShowMoreExist, setIsShowMoreExist] = useState<boolean>(false);
  const [shortCompanyDescription, setShortCompanyDescripiton] =
    useState<string>("");

  const onFilterChange = ({
    key,
    value,
  }: {
    key: "location" | "workField";
    value: string;
  }) => {
    dispatch(setCompanyFilter({ key, value }));
  };

  const handlePaginate = (number: number) => {
    dispatch(setPageNum(number));
  };

  const handleOnShowMoreLess = () => {
    setIsShowMore(!isShowMore);
  };

  useEffect(() => {
    dispatch(
      fetchCompanyInfo({
        companyKey: companyname as string,
        locationId: filterValue.location,
        workFieldId: filterValue.workField,
        pageSize,
        pageNum,
      })
    );
  }, [filterValue, pageNum]);

  useEffect(() => {
    if (company.about_us && isShowMore) {
      const companyAboutUsElement: any =
        document.querySelector(".company-about-us");
      const isShowMoreCheck =
        companyAboutUsElement?.offsetHeight <
        companyAboutUsElement?.scrollHeight
          ? true
          : false;
      setIsShowMoreExist(isShowMoreCheck);

      const computedStyle = getComputedStyle(companyAboutUsElement);

      const width = computedStyle
        ? parseInt(computedStyle.getPropertyValue("width"))
        : 0;

      const fullText = companyAboutUsElement.textContent;
      const chartWidth = 6.3;
      const maximumTextRows = 5;
      const maxCharacters = Math.floor((width / chartWidth) * maximumTextRows);
      const shortText = fullText.slice(0, maxCharacters);
      const spacingForShowMoreText = desktop ? -35 : -40;
      const formattedShortText = shortText.slice(0, spacingForShowMoreText);
      const elipsisShortText = formattedShortText + "...";
      setShortCompanyDescripiton(elipsisShortText);
    }
  }, [company.about_us]);

  return (
    <CompanyInfoComponent
      t={t}
      desktop={desktop}
      isLoading={isLoading}
      company={company}
      filterOptions={filterOptions}
      filterValue={filterValue}
      page={pageNum}
      error={error}
      industry={industry}
      isShowMore={isShowMore}
      isShowMoreExist={isShowMoreExist}
      shortCompanyDescription={shortCompanyDescription}
      onShowMoreLess={handleOnShowMoreLess}
      paginate={handlePaginate}
      onFilterChange={onFilterChange}
    />
  );
};

export default CompanyInfo;
