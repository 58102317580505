import { TFunction } from "i18next";
import React from "react";
import { DIALOG_TYPE } from "./common";

// api

export type TApiGetUserPayload = {
  readonly pagenum: number;
  readonly pagesize: number;
  readonly email?: string;
};

export type TApiDeleteUserPayload = {
  readonly id: string;
  readonly email: string;
};

export type TApiInviteUserPayload = {
  readonly emails: string[];
};

//

export type TTeamsInvitation = {
  readonly id: string;
  readonly email: string;
  readonly url_key: string;
  readonly company_id: string;
  readonly timestamp: string;
};

export type TTeamsMember = {
  readonly id: string;
  readonly username: string;
  readonly firstname: string;
  readonly lastname: string;
  readonly profile_photo: string | null;
  readonly photo: string | null;
  readonly phonenumber: string;
  readonly password: string;
  readonly salt: string;
  readonly status: string;
  readonly company_id: string;
  readonly activation_key: string | null;
  readonly access_token: string;
  readonly access_token_expiration_timestamp: string;
  readonly is_admin: string;
  readonly is_superadmin: string;
  readonly disabled: string;
  readonly activated: string;
  readonly registered: string;
  readonly lang: string;
};

export interface ITeamsState {
  readonly filters: TTeamsFilters;
  readonly navigation: TTeamsNavigation;
  readonly pagination: TTeamsPagination;
  readonly users: TTeamsUsers;
  readonly error: unknown;
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
}

export type TTeamsFilters = {
  readonly quickSearch: string;
};

export type TTeamsNavigation = {
  readonly userStatuses: TEAM_USER_STATUSES[];
  readonly selectedUserStatus: TEAM_USER_STATUSES;
};

export type TTeamsPagination = {
  readonly joined: {
    readonly pageNum: number;
    readonly pageSize: number;
  };
  readonly pending: {
    readonly pageNum: number;
    readonly pageSize: number;
  };
};

export type TTeamsUsers = {
  readonly list: (TTeamsMember & TTeamsInvitation)[];
  readonly totalCount: number;
};

export interface ITeamsComponent {
  readonly t: TFunction;
  readonly userList: TTeamsUsers["list"];
  readonly isLoading: boolean;
  readonly isListLoading: boolean;
  readonly openedDialogType: DIALOG_TYPE | null;
  readonly pageNum: number;
  readonly pageSize: number;
  readonly userCount: number;
  readonly quickSearch: string;
  readonly emailsToInvite: string[];
  readonly actions: {
    readonly onDelete?: (data: TTeamsMember | TTeamsInvitation) => void;
    readonly onReInviteTeamMember?: (email: string) => void;
  };
  readonly onPaginate: (pageNum: number) => void;
  readonly onChangeFilters: (value: string) => void;
  readonly onCloseDialog: () => void;
  readonly onDelete: () => void;
  readonly onEmailsChange: (emails: string[]) => void;
  readonly onOpenDialog: ({
    type,
    user,
  }: {
    type: DIALOG_TYPE;
    user?: TTeamsMember | TTeamsInvitation;
  }) => void;
  readonly onInvite: () => void;
  readonly onTabChange: (
    event: React.SyntheticEvent,
    value: TEAM_USER_STATUSES
  ) => void;
  readonly selectedUserStatus: TEAM_USER_STATUSES;
  readonly userStatuses: TEAM_USER_STATUSES[];
}

export interface ITeamsToolbar {
  readonly t: TFunction;
  readonly quickSearch: ITeamsComponent["quickSearch"];
  readonly onChangeFilters: ITeamsComponent["onChangeFilters"];
  readonly onOpenDialog: ITeamsComponent["onOpenDialog"];
}

export interface ITeamsNoResultOverlay {
  readonly t: TFunction;
  readonly onOpenDialog: ITeamsComponent["onOpenDialog"];
}

export interface ITeamsNoResultComponent {
  readonly actionText: string;
  readonly titleText: string;
  readonly hasFilters: boolean;
  readonly onResetFilters: () => void;
  readonly onOpenDialog: () => void;
}

export interface ITeamsInviteDialog {
  t: TFunction;
  title: string;
  isLoading: boolean;
  isOpen: boolean;
  emailsToInvite: string[];
  onCancel: () => void;
  onEmailsChange: ITeamsComponent["onEmailsChange"];
  onInvite: () => void;
}

export interface IApplyJobDialog {
  isOpen: boolean;
  onCancel: () => void;
}

export enum TEAM_USER_STATUSES {
  JOINED = "joined",
  PENDING = "pending",
}

export enum TEAM_USER_ROLES {
  ADMIN = "Admin",
  RECRUITER = "Recruiter",
}
