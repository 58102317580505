import React from "react";
import ReactApexChart from "react-apexcharts";
import { IRadialBarChart } from "../../models/Chart";

const RadialBarChart = ({
  height = 350,
  isMultiple = true,
  series,
  labels,
}: IRadialBarChart) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      height,
      type: "radialBar",
    },
    legend: {
      show: true,
      position: "bottom",
      formatter(legendName: string, opts?: any): string {
        const series = opts.w.config.series;
        return `${legendName} ${series[opts.seriesIndex]}`;
      },
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
            formatter: (res) => res.toString(),
          },
          total: {
            show: isMultiple,
            label:
              localStorage.getItem("lang") === "en_US" ? "Total" : "Gesamt",
            formatter: ({ config }) => {
              const sum = config.series.reduce(
                (acc: number, item: number) => acc + item,
                0
              );
              return sum.toString();
            },
          },
        },
      },
    },
    labels,
  };

  return (
    <ReactApexChart
      series={series}
      options={options}
      height={height}
      type="radialBar"
    />
  );
};

export default RadialBarChart;
